import React from "react";
import { Row, Col, Typography, Divider } from 'antd';
// import "./index.css";
// import background6 from "../static/bgs/bg6.png";

const { Title, Paragraph, Text, Link } = Typography;

class TeamSection extends React.Component {
    render() {
        return (
            <div className="section section-team">
                <div className="container">

                    <div className="con-title">
                        <Title className="feature-title">
                            <span>Team</span>
                        </Title>
                    </div>

                    <div className="con-member">
                        <div className="member member-1 toprow">
                            <div className="member-img"></div>
                            <div className="member-tag">
                                <span className="tag-position">CEO</span>
                                <span className="tag-name">John Park</span>
                            </div>
                        </div>
                        <div className="member member-2 toprow">
                            <div className="member-img"></div>
                            <div className="member-tag">
                                <span className="tag-position">COMMUNITITY DIRECTOR</span>
                                <span className="tag-name">Taz LEE</span>
                            </div>
                        </div>
                        <div className="member member-3 toprow">
                            <div className="member-img"></div>
                            <div className="member-tag">
                                <span className="tag-position">Music Director</span>
                                <span className="tag-name">SD Park</span>
                            </div>
                        </div>
                        {/* <div className="member member-4 toprow">
                            <div className="member-img"></div>
                            <div className="member-tag">
                                <span className="tag-position">Marketing</span>
                                <span className="tag-name">SONG</span>
                            </div>
                        </div> */}
                        <div className="member member-4">
                            <div className="member-img"></div>
                            <div className="member-tag">
                                <span className="tag-position">LEAD DESIGNER</span>
                                <span className="tag-name">Randi</span>
                            </div>
                        </div>
                        <div className="member member-5">
                            <div className="member-img"></div>
                            <div className="member-tag">
                                <span className="tag-position">Art Director</span>
                                <span className="tag-name">ETHAN KANG</span>
                            </div>
                        </div>
                        <div className="member member-6">
                            <div className="member-img"></div>
                            <div className="member-tag">
                                <span className="tag-position">CTO</span>
                                <span className="tag-name">J.S</span>
                            </div>
                        </div>
                        {/* <div className="member member-8">
                            <div className="member-img"></div>
                            <div className="member-tag">
                                <span className="tag-position">Creative Director</span>
                                <span className="tag-name">JP</span>
                            </div>
                        </div> */}
                    </div>
                    <p className="team-desc">10% of 10,456 Goblins will be  <br />in Team Treasury for community activation  <br />and ecosystem support.</p>
                </div>
            </div>
        )
    }
}

export default TeamSection;