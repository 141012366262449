
import { useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import Advanced from '../resource/sounds/bgm/1. Advanced - To the Edge.mp3'
import Troy from '../resource/sounds/bgm/2. Troy - Running.mp3'
import Krein from '../resource/sounds/bgm/3. Krein - Raving Goblins.mp3'
import Tobirush from '../resource/sounds/bgm/4. Tobirush - Odyssey.mp3'

function MusicPlayer() {
  const musicTracks = [
    {
      name: "1. Advanced",
      title:'To the Edge',
      src: `${Advanced}`
    },
    {
      name: "2. Troy",
      title:'Running',
      src: `${Troy}`
    },
    {
      name: "3. Krein",
      title:'Raving Goblins',
      src: `${Krein}`
    },
    {
      name: "4. Tobirush",
      title:'Odyssey',
      src: `${Tobirush}`
    },

  ];

  const [trackIndex, setTrackIndex] = useState(0);

  const handleClickPrevious = () => {
    setTrackIndex((currentTrack) =>
      currentTrack === 0 ? musicTracks.length - 1 : currentTrack - 1
    );
  };

  const handleClickNext = () => {
    setTrackIndex((currentTrack) =>
      currentTrack < musicTracks.length - 1 ? currentTrack + 1 : 0
    );
  };

  return (
      <AudioPlayer
        id="soundbar"
        // autoPlay
        autoPlay={true}
        layout="horizontal-reverse"
        src={musicTracks[trackIndex].src}
        // onPlay={(e) => console.log("onPlay")}
        showSkipControls={true}
        showJumpControls={false}
        showDownloadProgress={false}
        customAdditionalControls={[
          <div className="music-title">{musicTracks[trackIndex].name} - <strong>{musicTracks[trackIndex].title}</strong></div>
        ]}
        footer="goblins"
        onClickPrevious={handleClickPrevious}
        onClickNext={handleClickNext}
        onEnded={handleClickNext}
        // customIcons={{
        //   play: 
        // }}
        // other props here
      />
  );
}

export default MusicPlayer;