import React, {useContext, useEffect, useState} from 'react';
import { notification } from "antd";
import "antd/dist/antd.css";
import axios from 'axios';

import { 
  nftaddress
} from '../config';

import NFT from '../artifacts/contracts/NFT.sol/NFT.json';
const AuthContext = React.createContext()
let Caver = window.caver;

export function useAuth(){
    return useContext(AuthContext)
}
export function AuthProvider({children}) {

    const [currentUser, SetCurrentUser] =useState()
    const [balanceUser, SetBalance] =useState()
    const [net, SetNet] =useState()
    const [isloading, Setloading] =useState(true)

    useEffect(() => {

       Setloading(false)
       loadAccountInfo()
       
    },[currentUser])


    async function loadAccountInfo(){
      const { klaytn } = window
  
      if (klaytn) {
        try {
          await klaytn.enable()

          SetCurrentUser(klaytn.selectedAddress)
          const balance = await Caver.klay.getBalance(klaytn.selectedAddress)
          SetBalance(Caver.utils.fromPeb(balance, 'KLAY'))

          klaytn.on('accountsChanged', async() => {
            SetCurrentUser(klaytn.selectedAddress)
            const balance = await Caver.klay.getBalance(klaytn.selectedAddress)
            SetBalance(Caver.utils.fromPeb(balance, 'KLAY'))
          
          })
        } catch (error) {
          notification.info({
            message: "User denied account access",
            placement: "topRight",
            icon: <SmileOutlined style={{ color: 'white' }} />,
          });
          console.log('User denied account access')
        }
      } else {

        notification.info({
          message: "Non-Kaikas browser detected. You should consider trying Kaikas!",
          placement: "topRight",
          icon: <SmileOutlined style={{ color: 'white' }} />,
        });
        console.log('Non-Kaikas browser detected. You should consider trying Kaikas!')
      }
   }

   async function setNetworkInfo(){
    const { klaytn } = window
    if (klaytn === undefined) return

    SetNet(klaytn.networkVersion)
    klaytn.on('networkChanged', () => SetNet(klaytn.networkVersion))
  }

  async function setAccount(){

    const { klaytn } = window
    if (klaytn === undefined) return
    await new Promise((resolve, reject) => setTimeout(resolve, 500))
    const account = klaytn.selectedAddress
    const balance = await Caver.klay.getBalance(account)
  
    SetCurrentUser(account)
    SetBalance(Caver.utils.fromPeb(balance, 'KLAY'))
  
}
  
    const value ={
        balanceUser,
        net,
        currentUser, 
        loadAccountInfo,
        setNetworkInfo,
        setAccount,
    

    }

    return (
        <AuthContext.Provider value={value}>
            {!isloading && children}
        </AuthContext.Provider>
    )
}


