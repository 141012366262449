import React from 'react';
import '../resource/css/socials.css'
import {FaDiscord, FaTwitterSquare, FaTelegram} from 'react-icons/fa'

function Socials() {
  return (
    <div class="float-sm">
    <div class="fl-fl float-fb">
      <i className='fas'> <FaDiscord /> </i>
      <a href="" target="_blank"> Discord !</a>
    </div>
    <div class="fl-fl float-tw">
      <i class="fas"><FaTwitterSquare /></i>
      <a href="" target="_blank">Twitter!</a>
    </div>
    <div class="fl-fl float-gp">
      <i class="fas"> <FaTelegram /></i>
      <a href="" target="_blank">Telegram!</a>
    </div>
   
  </div>
  );
}

export default Socials;
