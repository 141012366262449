import React from "react";
import { Row, Col, Typography, Divider } from 'antd';
// import "./index.css";
// import background4 from "../static/bgs/bg4.png";

const { Title, Paragraph, Text, Link } = Typography;

class RaritySection extends React.Component {
	render() {

		$('.grade-button').off().on('click', function (e) {
			e.stopPropagation()
			$(this).toggleClass('tab-open')
		})
		return (
			<div className="section section-rarity">
				<div className="container">
					<div className="con-title">
						<Title className="feature-title">
							<span>rarity</span>
						</Title>
					</div>

					<div className="grade-tab-wrap">
						<div className="grade-tab legende">
							<div className="grade-button">
								<div><span>LEGEND<span> </span>0.01% ~ 0.10%</span></div>
							</div>

							<div className="grade-cont">
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>K-King</strong>
										<p><span>QUANTITY</span> <span>1</span></p>
										<p><span>RARITY</span> <span>0.01%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>K-Queen</strong>
										<p><span>QUANTITY</span> <span>2</span></p>
										<p><span>RARITY</span> <span>0.02%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>K-SUNBI</strong>
										<p><span>QUANTITY</span> <span>3</span></p>
										<p><span>RARITY</span> <span>0.03%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>K-MUGUAN</strong>
										<p><span>QUANTITY</span> <span>4</span></p>
										<p><span>RARITY</span> <span>0.04%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>K-POJOL</strong>
										<p><span>QUANTITY</span> <span>5</span></p>
										<p><span>RARITY</span> <span>0.05%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>ART 1</strong>
										<p><span>QUANTITY</span> <span>6</span></p>
										<p><span>RARITY</span> <span>0.06%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>ART 2</strong>
										<p><span>QUANTITY</span> <span>7</span></p>
										<p><span>RARITY</span> <span>0.07%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>ART 3</strong>
										<p><span>QUANTITY</span> <span>8</span></p>
										<p><span>RARITY</span> <span>0.08%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>ART 4</strong>
										<p><span>QUANTITY</span> <span>9</span></p>
										<p><span>RARITY</span> <span>0.09%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>ART 5</strong>
										<p><span>QUANTITY</span> <span>10</span></p>
										<p><span>RARITY</span> <span>0.10%</span></p>
									</div>
								</div>
								<div className="grade-bg-wrap bg-col2">
									<div>
										<span className="grade-bg"></span>
										<strong>Back Ground</strong>
									</div>
								</div>
							</div>
						</div>

						<div className="grade-tab superrare">
							<div className="grade-button">
								<div><span>SUPERRARE<span> </span>0.11% ~ 0.29%</span></div>
							</div>

							<div className="grade-cont">
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>Musician 1</strong>
										<p><span>QUANTITY</span> <span>11</span></p>
										<p><span>RARITY</span> <span>0.11%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>Musician 2</strong>
										<p><span>QUANTITY</span> <span>12</span></p>
										<p><span>RARITY</span> <span>0.11%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>Musician 3</strong>
										<p><span>QUANTITY</span> <span>13</span></p>
										<p><span>RARITY</span> <span>0.12%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>Musician 4</strong>
										<p><span>QUANTITY</span> <span>14</span></p>
										<p><span>RARITY</span> <span>0.13%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>Monroe</strong>
										<p><span>QUANTITY</span> <span>15</span></p>
										<p><span>RARITY</span> <span>0.14%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>Jobs</strong>
										<p><span>QUANTITY</span> <span>16</span></p>
										<p><span>RARITY</span> <span>0.15%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>Astronaut</strong>
										<p><span>QUANTITY</span> <span>17</span></p>
										<p><span>RARITY</span> <span>0.16%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>Zombie</strong>
										<p><span>QUANTITY</span> <span>18</span></p>
										<p><span>RARITY</span> <span>0.17%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>Dark Angel</strong>
										<p><span>QUANTITY</span> <span>20</span></p>
										<p><span>RARITY</span> <span>0.19%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>Devil</strong>
										<p><span>QUANTITY</span> <span>22</span></p>
										<p><span>RARITY</span> <span>0.21%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>Angel</strong>
										<p><span>QUANTITY</span> <span>24</span></p>
										<p><span>RARITY</span> <span>0.23%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>Error:Blur</strong>
										<p><span>QUANTITY</span> <span>26</span></p>
										<p><span>RARITY</span> <span>0.25%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>Error:Glitch</strong>
										<p><span>QUANTITY</span> <span>28</span></p>
										<p><span>RARITY</span> <span>0.27%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>Error:Twin</strong>
										<p><span>QUANTITY</span> <span>30</span></p>
										<p><span>RARITY</span> <span>0.29%</span></p>
									</div>
								</div>
								<div className="grade-bg-wrap bg-col1">
									<div>
										<span className="grade-bg"></span>
										<strong>Back Ground</strong>
									</div>
								</div>
							</div>

						</div>

						<div className="grade-tab rare">
							<div className="grade-button">
								<div><span>RARE<span> </span>0.32% ~ 0.86%</span></div>
							</div>

							<div className="grade-cont">
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>RAVER:Smile</strong>
										<p><span>QUANTITY</span> <span>33</span></p>
										<p><span>RARITY</span> <span>0.32%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>RAVER:Drunken</strong>
										<p><span>QUANTITY</span> <span>36</span></p>
										<p><span>RARITY</span> <span>0.34%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>RAVER:Peace</strong>
										<p><span>QUANTITY</span> <span>39</span></p>
										<p><span>RARITY</span> <span>0.37%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>RAVER:Flower</strong>
										<p><span>QUANTITY</span> <span>42</span></p>
										<p><span>RARITY</span> <span>0.40%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>RAVER:Green</strong>
										<p><span>QUANTITY</span> <span>45</span></p>
										<p><span>RARITY</span> <span>0.43%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>RAVER:Candy1</strong>
										<p><span>QUANTITY</span> <span>48</span></p>
										<p><span>RARITY</span> <span>0.46%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>RAVER:Candy2</strong>
										<p><span>QUANTITY</span> <span>51</span></p>
										<p><span>RARITY</span> <span>0.49%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>RAVER:Mirror Ball</strong>
										<p><span>QUANTITY</span> <span>54</span></p>
										<p><span>RARITY</span> <span>0.52%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>RAVER:Speaker</strong>
										<p><span>QUANTITY</span> <span>60</span></p>
										<p><span>RARITY</span> <span>0.57%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>RAVER:Gamer</strong>
										<p><span>QUANTITY</span> <span>65</span></p>
										<p><span>RARITY</span> <span>0.62%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>RAVER:Love</strong>
										<p><span>QUANTITY</span> <span>70</span></p>
										<p><span>RARITY</span> <span>0.67%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>RAVER:Unity</strong>
										<p><span>QUANTITY</span> <span>75</span></p>
										<p><span>RARITY</span> <span>0.72%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>RAVER:Smoker2</strong>
										<p><span>QUANTITY</span> <span>80</span></p>
										<p><span>RARITY</span> <span>0.77%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>RAVER:Smoker1</strong>
										<p><span>QUANTITY</span> <span>85</span></p>
										<p><span>RARITY</span> <span>0.81%</span></p>
									</div>
								</div>
								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>RAVER:Acid</strong>
										<p><span>QUANTITY</span> <span>90</span></p>
										<p><span>RARITY</span> <span>0.86%</span></p>
									</div>
								</div>
								<div className="grade-bg-wrap bg-col3">
									<div>
										<span className="grade-bg"></span>
										<strong>Back Ground</strong>
									</div>
								</div>
							</div>
							
						</div>

						<div className="grade-tab uncommon normal">
							<div className="grade-button">
								<div><span>UNCOMMON<span> </span>19.91%</span></div>
							</div>

							<div className="grade-cont">
								<div className="comb-wrap">
									<div className="grade-goblins">
										<span className="grade-goblins-images uncommon"></span>
										<div>
											<strong>UNCOMMON</strong>
											<p><span>QUANTITY</span> <span>2,082</span></p>
											<p><span>RARITY</span> <span>19.91%</span></p>
										</div>
									</div>

									<span className="grade-combi-arrow"></span>

									<div className="combi-wrap">
										<span className="combi-goblins-image"></span>
										<strong>RANDOM COMBINATION </strong>
										<p>BODY+HAT+SCARF+BAT+SHOES+BACK GROUND</p>
									</div>
								</div>

								<div className="combi-div">
									<strong>BODY #1~20</strong>
									<span className="uncommon-body"></span>
								</div>
								<div className="combi-div">
									<strong>BAT #1~20</strong>
									<span className="uncommon-bat"></span>
								</div>
								<div className="combi-div">
									<strong>HAT #1~20</strong>
									<span className="uncommon-hat"></span>
								</div>
								<div className="combi-div">
									<strong>SCARF #1~20</strong>
									<span className="uncommon-scarf"></span>
								</div>
								<div className="combi-div">
									<strong>SHOES #1~20</strong>
									<span className="uncommon-shoes"></span>
								</div>
								<div className="combi-div">
									<strong>BACK GROUND</strong>
									<span className="uncommon-bg"></span>
								</div>
							</div>
							
						</div>

						<div className="grade-tab common normal">
							<div className="grade-button">
								<div><span>COMMON<span> </span>64.31%</span></div>
							</div>

							<div className="grade-cont">
								<div className="comb-wrap">
									<div className="grade-goblins">
										<span className="grade-goblins-images"></span>
										<div>
											<strong>COMMON</strong>
											<p><span>QUANTITY</span> <span>6,724</span></p>
											<p><span>RARITY</span> <span>64.31%</span></p>
										</div>
									</div>

									<span className="grade-combi-arrow"></span>

									<div className="combi-wrap">
										<span className="combi-goblins-image common"></span>
										<strong>RANDOM COMBINATION </strong>
										<p>BODY+SCARF+SHOES+BACK GROUND</p>
									</div>
								</div>

								<div className="combi-div">
									<strong>BODY #1~20</strong>
									<span className="common-body"></span>
								</div>
								<div className="combi-div">
									<strong>SCARF #1~20</strong>
									<span className="common-scarf"></span>
								</div>
								<div className="combi-div">
									<strong>SHOES #1~20</strong>
									<span className="common-shoes"></span>
								</div>
								<div className="combi-div">
									<strong>BACK GROUND</strong>
									<span className="common-bg"></span>
								</div>
							</div>
							
						</div>

						<div className="grade-tab event">
							<div className="grade-button">
								<div><span>EVENT<span> </span>4.36%</span></div>
							</div>

							<div className="grade-cont">

								<div className="grade-goblins">
									<span className="grade-goblins-images"></span>
									<div>
										<strong>EVENT #001~456</strong>
										<p><span>QUANTITY</span> <span>456</span></p>
										<p><span>RARITY</span> <span>4.36%</span></p>
									</div>
								</div>
								<div className="grade-goblins event-col2">
									<div>
										<span className="grade-goblins-images"></span>
										<span className="grade-goblins-images"></span>
									</div>
									<strong>#001~456 + Back Ground(white)</strong>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default RaritySection;