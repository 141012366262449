import React from "react";
import { Row, Col, Typography, Divider } from 'antd';
import "./index.css";
// import background7 from "../static/bgs/bg7.gif";

const { Title, Paragraph, Text, Link } = Typography;

class PartnerSection extends React.Component {
    render() {
        return (
            <div className="section section-partner">

                <div className="container">
                    <div className="con-partner">
                        <p className="title">Partners</p>
                        <ul>
                            <li className="logo logo-1"></li>
                            <li className="logo logo-2"></li>
                            <li className="logo logo-3"></li>
                            {/* <li className="logo logo-4"></li> */}
                            <li className="logo logo-5"></li>
                            <li className="logo logo-6"></li>
                            <li className="logo logo-7"></li>
                            <li className="logo logo-8"></li>
                            <li className="logo logo-9"></li>
                            <li className="logo logo-10"></li>
                            <li className="logo logo-11"></li>
                            <li className="logo logo-12"></li>
                        </ul>
                    </div>
                    <div className="con-join">
                        <p className="title">Join our community</p>
                        <ul>
                            <li className="logo discord">

                                <a href="https://discord.gg/54rYGQMGKN" target="_blank">Discord</a>

                            </li>
                            <li className="logo twitter">
                                <a href="https://twitter.com/raving_goblins" target="_blank">Twitter</a>
                            </li>
                            <li className="logo opensea">
                                <a href="https://opensea.io/collection/ravinggoblinsclub" target="_blank">Opensea</a>
                            </li>
                            <li className="logo instagram">
                                <a href="https://www.instagram.com/raving_goblins/" target="_blank">Instagram</a>
                            </li>
                            <li className="logo telegram">
                                <a href="https://t.me/raving_goblins" target="_blank">Telegram</a>
                            </li>
                            <li className="logo kakao">
                                <a href="https://open.kakao.com/o/gvZM6UYd" target="_blank">Kakao</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default PartnerSection;