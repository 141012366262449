import React, { useCallback, useEffect, useState } from "react";
import { Alert, Button, Row, Card, Col, Input, List, Menu, Anchor } from 'antd';
import { useAuth } from "../context/Context";
import { SmileOutlined } from '@ant-design/icons';
import { AlertOutlined } from '@ant-design/icons';

import "./index.css";
// import background3 from "../static/bgs/bg3.gif";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import SwiperCore,{ Navigation } from "swiper";
//sounds
import buttonClick from "../resource/sounds/buttonClick.wav";
import quantityClick from "../resource/sounds/quantityClick.wav";
import lever from "../resource/sounds/lever.wav";
import capsuleMix from "../resource/sounds/capsuleMix.wav";
import capsuleMove from "../resource/sounds/capsuleMove.wav";
import capsuleOpen from "../resource/sounds/capsuleOpen.wav";
import coverOpen from "../resource/sounds/coverOpen.wav";
import capsuleDrop1 from "../resource/sounds/capsuleDrop1.wav";
import goblinShow from "../resource/sounds/goblinShow.mp3";
import mintSound from "../resource/sounds/mint-sound.mp3";
import mintSound2 from "../resource/sounds/mint-sound.mp3";

import axios from "axios";
import { notification } from "antd";
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";
import "antd/dist/antd.css";
import Web3Modal from "web3modal";
import GoblinSlider from "./GoblinSlider";

import { 
  nftaddress
} from '../config';

import NFT from '../artifacts/contracts/NFT.sol/NFT.json';

let Caver = window.caver;
let numberMint=1
// main react function for main page
function MintSection(props) {

  const {
    balanceUser,
    net,
    currentUser, 
    loadAccountInfo,
    setNetworkInfo,
    setAccount,
    
} = useAuth();   

  const [minting, SetMinting]=useState(false)
  const [total, setTotal]=useState()
  const [added, setAdded]=useState()
  const [userallowed, setUserallowed]=useState([])
  const [ddbid, setID]=useState([])

useEffect(() => {
  GetuserCount()
},[currentUser])

async function GetuserCount(){
  await axios.get(`https://raving-open-api.com/api/check/${currentUser}`).then((res)=>{
    console.log("Userrrrrrr========",res?.data?.length) // undefined and 1
    const r = res?.data?.length
    if(typeof r ==='undefined'){
      console.log(r)
      setUserallowed(0)
    } else if(typeof r !=='undefined'){
      setUserallowed(res?.data[0]?.total)
      setID(res?.data[0]?.id)
    } 
    
  }).then((err)=>{
    console.log(err)
  })

}

  let receivedGoblins=[]

  const mintImageSet = function(){
    $('.swiper-slide').each(function(i){
      $(this).find('.trans-goblin').css(
        'background-image',
        'url("'+receivedGoblins[i].resultUrl+'")'
        )
      $(this).find('.result-goblin').css(
        'background-image',
        'url("'+receivedGoblins[i].resultUrl+'")'
        )
    })
  }

  useEffect(()=>{
    GetCountNFT()
  },[added])

const GetCountNFT =async() =>{

  /*let contract = new  Caver.klay.Contract(NFT.abi, nftaddress)
 
   await contract.methods._batch().call({
     gas: '25000000'
   }).then((res)=>{
     console.log("total minted",res)
   }).catch((err)=>{
     console.log(err)
   })*/
 
   await axios.get(`https://raving-open-api.com/api/gettotal`).then((res)=>{
     console.log(res.data[0].total)
     setTotal(res?.data[0]?.total)
   }).then((err)=>{
     console.log(err)
   })
 
 }
 
 
  const ClaimNft = async() => {

    if (getFinalqty()+(total-1000)>9456){  {/*  if (getFinalqty()>userallowed) */}

      notification.info({
        message: `Only ${10456-(total-1000)-1000} NFT(s) left `,
        placement: "topRight",
        icon: <AlertOutlined style={{ color: 'red' }} />,
      });

    } else{

      try {
        mintAction()
        SetMinting(true)

        notification.info({
          message: "The Minting process has begun",
          placement: "topRight",
          icon: <SmileOutlined style={{ color: 'blue' }} />,
        });

      console.log("Final quatity",getFinalqty())
  
        console.log("qty wanted ====== and user", getFinalqty(),  currentUser)
        const price = 170
        const totalprice = getFinalqty()*price
        let contract = new  Caver.klay.Contract(NFT.abi, nftaddress)
        let value_tx = Caver.utils.toPeb(totalprice.toString(), "KLAY")
      
        await contract.methods.claimRaving(getFinalqty()).send({
          from: currentUser,
          value: value_tx,
          gas: '25000000'
        }).then(async(res)=>{
      
        
          const updateU ={id:Number(ddbid), total:getFinalqty()}
          await axios.post(`https://raving-open-api.com/api/updateuser`,updateU).then((res)=>{
      
          console.log(res)
          setAdded(res.data)
          SetMinting(false)
          GetuserCount()
      
          }).catch((err)=>{
            console.log(err)
            SetMinting(false)
          })
          

          const add ={total:getFinalqty()}
          await axios.post(`https://raving-open-api.com/api/addminted`,add).then((res)=>{
      
          console.log(res)
          setAdded(res.data)
          SetMinting(false)
          GetCountNFT()
          goblinAction()

          notification.error({
            message: `Success!`,
            placement: "bottomRight",
            icon: <SmileOutlined style={{ color: 'blue' }} />,
          });

          }).catch((err)=>{
            console.log(err)
            SetMinting(false)

            notification.error({
              message: `failed, could not mint`,
              placement: "topRight",
              icon: <AlertOutlined style={{ color: 'red' }} />,
            });

          })
      
        }).catch((err)=>{
          console.log(err)
          SetMinting(false)

          notification.error({
            message: `Failed, could not mint`,
            placement: "topRight",
            icon: <AlertOutlined style={{ color: 'red' }} />,
          });

        })
  

  } catch (error) {
    notification.error({
      message: `Failed`,
      placement: "bottomRight",
      icon: <AlertOutlined style={{ color: 'red' }} />,
    });

    SetMinting(false)
    
  }
      
    }
 

  
  }

  let goblinStep2
  let goblinStep3
  let goblinStep4
  let muchineStep1
  let muchineStep2
  let modalStep
  let $mintSound
  let $mintSound2
  let $coverOpen

  (()=> {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || (navigator.maxTouchPoints == 5)) {
      $('html').addClass('mobile')
    }


    let $buttonClick = document.getElementById("buttonClick")
    $mintSound = document.getElementById("mintSound")
    $mintSound2 = document.getElementById("mintSound2")
    $coverOpen = document.getElementById("coverOpen")
    // let $lever = document.getElementById("lever")
    // let $capsuleMix = document.getElementById("capsuleMix")
    // let $coverOpen = document.getElementById("coverOpen")

    $('.mint-section').on('click', '.pushable', function() {
      $buttonClick.play()
    })

    $('.mint-section').on('click', '.skip-btn', function() {
      $buttonClick.play()
    })

    $('.mint-section').on('click', '.mint-btn', function() {
      // $mintSound.play()
      // setTimeout(function () {
      //   // muchineStep1 = $capsuleMix.volume = 0.6
      //   // $capsuleMix.currentTime = 7
      //   // $capsuleMix.play()
        
      //   // $lever.currentTime = 1
      //   // $lever.play()
      // },500)

      // muchineStep2 = setTimeout(function () {
      //   // $coverOpen.play()
      // },1000)
    })

  })()
  
  let goblinAction = function () {
    clearTimeout(muchineStep1)
    clearTimeout(muchineStep2)
    
    $mintSound2.currentTime = 3.17
    $mintSound2.play()

    $('.swiper-slide-active').find('.goblin-capsule').not('.motion-end').addClass('step1')

    goblinStep2 = setTimeout(function (){
      $('.swiper-slide-active').find('.goblin-capsule').not('.motion-end').addClass('step2')
      if(!$('.swiper-slide-active').find('.motion-end').length){
        // document.getElementById("goblinShow").play()
      }
    },1300)

    goblinStep3 = setTimeout(function (){
      $('.swiper-slide-active').find('.goblin-capsule').not('.motion-end').addClass('step3')
    },4000)

    goblinStep4 = setTimeout(function (){
      $('.swiper-slide-active').find('.goblin-capsule').not('.motion-end').addClass('step4')
    },5000)
  }

  let mintAction = function() {
    $mintSound.play()

    $mintSound.addEventListener('timeupdate', function () {
      if($mintSound.currentTime > 2.18){
        $coverOpen.play()
      }
      if($mintSound.currentTime > 2.2){
        $mintSound.pause()
      }
    });

    $('.mint-section').addClass('step1')
    $('html').addClass('mint-click')
    $('.swiper-slide').addClass('swiper-slide-active')

    modalStep = setTimeout(function (){
      $('.mint-section').addClass('step2')
      //goblinAction()
      // document.getElementById("capsuleDrop1").currentTime=0
      if(!$('.swiper-slide-active').find('.motion-end').length){
        // document.getElementById("capsuleDrop1").play()
      }
    },3000)
    // document.getElementById("backgroundMusic").volume = 0.3

    // console.log(receivedGoblins);
    // mintImageSet()
  }

  const [slideList, setslideList, slide] = useState([0])
  let goblinsSlideArr =  [...slideList]
  let addArr
  let resetArr

  const onAddSlide = () => {
    addArr = goblinsSlideArr[0]
    goblinsSlideArr.push(addArr)
    setslideList(goblinsSlideArr)
  }

  const onRemoveSlide = () => {
    goblinsSlideArr.pop(goblinsSlideArr.length)
    setslideList(goblinsSlideArr)
  }

  let quantityAction = function(e) {
    let $this = $(e.target)
    let $num =  $('.quantity-num')
    let count = parseInt($num.html())
    let $auraGoblins = $('<span class="aura-goblin"></span>')
    let idx

    if($this.hasClass('plus-btn') && count < 10){ // change here the qty
      document.getElementById("quantityClick").play()
      $num.html(function(i, val) { 
        idx = val*1+1 
        return idx
      });

      $('.aura-goblins-wrap').attr('data-goblins-idx', idx).prepend($auraGoblins)
    } 

    if($this.hasClass('minus-btn') && count > 1){
      document.getElementById("quantityClick").play()
      $num.html(function(i, val) { 
        idx = val*1-1 
        return idx
      });

      $('.aura-goblins-wrap').attr('data-goblins-idx', idx).find('.aura-goblin').last().remove()
    }
  }

  let getFinalqty = function() {
 
    let $num =  $('.quantity-num')
    return parseInt($num.html())

  }

  let slideAction = function() {
    // document.getElementById("capsuleDrop1").currentTime=0
    // document.getElementById("goblinShow").currentTime=0
    // document.getElementById("capsuleDrop1").pause()
    // document.getElementById("goblinShow").pause()

    // if($('.goblin-capsule').hasClass('step1')){
      clearTimeout(goblinStep2)
      clearTimeout(goblinStep3)
      clearTimeout(goblinStep4)
      $('.goblin-capsule.step1').removeClass('step1 step2 step3 step4').addClass('motion-end')
    // }
    //goblinAction()
  }

  let skip = function() {
    clearTimeout(muchineStep1)
    clearTimeout(muchineStep2)
    clearTimeout(goblinStep2)
    clearTimeout(goblinStep3)
    clearTimeout(goblinStep4)
    clearTimeout(modalStep)
    // document.getElementById("capsuleDrop1").currentTime=0
    // document.getElementById("goblinShow").currentTime=0
    // document.getElementById("capsuleDrop1").pause()
    // document.getElementById("goblinShow").pause()

    document.getElementById("mintSound").currentTime=0
    document.getElementById("mintSound").pause()
    document.getElementById("mintSound2").currentTime=0
    document.getElementById("mintSound2").pause()

    resetArr = goblinsSlideArr.slice(1, goblinsSlideArr.length-1)
    goblinsSlideArr.length = 0;
    goblinsSlideArr.push(resetArr)
    setslideList(goblinsSlideArr)
    
    $('.quantity-num').text('1')

    setTimeout(function (){
      // clearTimeout(muchineStep1)
      // clearTimeout(muchineStep2)
      // clearTimeout(goblinStep2)
      // clearTimeout(goblinStep3)
      // clearTimeout(goblinStep4)
      // clearTimeout(modalStep)
      // // document.getElementById("capsuleDrop1").currentTime=0
      // // document.getElementById("goblinShow").currentTime=0
      // // document.getElementById("capsuleDrop1").pause()
      // // document.getElementById("goblinShow").pause()

      // document.getElementById("mintSound").currentTime=0
      // document.getElementById("mintSound").pause()
  
      $('.mint-section').removeClass('step1 step2')
      $('.goblin-capsule').removeClass('step1 step2 step3 step4 motion-end')
      $('.trans-goblin').removeAttr('style')
      $('.result-goblin').removeAttr('style')

      $('html').removeClass('mint-click')
      $('.swiper-slide').removeClass('swiper-slide-active')
      $('.aura-goblins-wrap').attr('data-goblins-idx', 1).find('.aura-goblin').remove()
      $('.aura-goblins-wrap').append($('<span class="aura-goblin"></span>'))
    },500)

    // document.getElementById("backgroundMusic").volume = 1
  }

  SwiperCore.use([Navigation])

  return (
    <section className="section mint-section">
      <div className="muchine-wrap">
        <div className="goblin-wrap">
          <strong className="mint-count">
            <span className="selling-count">9456</span>
            {/* <span className="selling-count">{total-1000}</span> */}
            /<span className="total-count">9456</span>
          </strong>
          <div className="goblin-light">
            <p className="hidden-goblin"></p>
          </div>
        </div>

        <div className="gacha-muchine">
          <div className="machine_body">
            <p className="machine_showcase"></p>
            <p className="machine_cover"></p>
            <p className="machine_lever"></p>
            <p className="machine_capsule"></p>
          </div>
          <div className="gas-wrap">
            <p className="gas_gauge"></p>
          </div>
        </div>

        <div className="btn-wrap">
          <div className="quantity-wrap">
            <p className="mint-price">
              MINTING PRICE <br/>170 KLAY/GOBLIN
            </p> 

            {!((total-1000)<9456) && (<strong className="mint-title">FIRST DROP IS OVER</strong>)}

            {(total-1000)<9456 && (<div><strong className="mint-title">QUANTITY</strong>
            <p className="quantity-control"> {/* onClick={(e)=>
                quantityAction(e)
              }*/}
              
              <button className="quantity-btn minus-btn"
              onClick={(e)=>
                quantityAction(e)
              }
             >-</button>
              <span className="quantity-num">10</span> 
              <button className="quantity-btn plus-btn"
              onClick={(e)=>
                quantityAction(e)
              }
             >+</button>
            </p></div>)}
          </div>

         {/*(total-1000)<9456 && currentUser && (<button className="pushable mint-btn"
              onClick={()=>
                ClaimNft()
              }
              disabled={minting?true:false}>
            <span className="front">MINT </span>
            </button>) */} 
          
          {/* {!currentUser&&<button className="pushable wallet-btn" onClick={()=>{loadAccountInfo();setAccount()}}> 
            <span className="front">WALLET</span>
          </button>}  */}

          {currentUser&&<div style={{fontSize:'14px', color:'black', textAlign:'start', justifyContent: 'center', width:'12rem', backgroundColor:'#D6E5FA', paddingLeft:'1rem', paddingTop:'1rem', paddingBottom:'1rem',borderRadius:'10px'}}>
            <span >Welcome: {currentUser.slice(0,4)+'...'+currentUser.slice(37,43)}</span> < br />
            <span >Balance: {balanceUser} KLAY</span> <br />
            <span >Minting count: Unlimited.</span>
          </div>} 
         
          {/* 
          <div style={{fontSize:'14px', color:'black', textAlign:'start', justifyContent: 'center', width:'12rem', backgroundColor:'#F9C5D5', paddingLeft:'1rem', paddingTop:'1rem', paddingBottom:'1rem',borderRadius:'10px'}}>

            <span>Price: 95 KLAY/Goblin</span> < br />
            <span>Hurry! only 2343 left out of 5000 Goblins</span> 
            
          </div> */}

          {/* <div style={{fontSize:'14px', color:'white', textAlign:'center', justifyContent: 'center', height:'20px'}}> {minting&&<i>We are minting your Raving Goblin, please wait ...</i>}</div> */}
        </div>
      </div>

      <div className="modal-popup">
        <div className="modal-wrap">
          {/* <GoblinSlider slideList={slideList} slide={slideAction}/> */}

          <div id="mint-slider" className="no-slide">
            <div className="swiper-slide">
              <div className="goblin-capsule">
                <p className="cap01"></p>
                <p className="goblin-image">
                  <span className="fake-goblin"></span>
                  <div className="aura-goblins-wrap" data-goblins-idx="1">
                    <span className="aura-goblin"></span>
                  </div>
                </p>
                <p className="cap02"></p>
                <p className="speech-bubble">
                  <span>See you at Opensea!</span>
                </p>
              </div>
            </div>
          </div>
          <div className="modal-btn-wrap">
            <button className="skip-btn" onClick={()=>skip()}>SKIP</button>
            <a href={`https://opensea.io/account`} target="_blank" className="skip-btn">WALLET</a>
          </div>
        </div>
      </div>
      <div className="device-mode-cover">
        <p className="cover-image"></p>
      </div>
      <div className="audio-wrap">
        <audio id='buttonClick' src={buttonClick}></audio>
        <audio id='quantityClick' src={quantityClick}></audio>
        <audio id='lever' src={lever}></audio>
        <audio id='capsuleMix' src={capsuleMix}></audio>
        <audio id='capsuleMove' src={capsuleMove}></audio>
        <audio id='capsuleOpen' src={capsuleOpen}></audio>
        <audio id='coverOpen' src={coverOpen}></audio>
        <audio id='capsuleDrop1' src={capsuleDrop1}></audio>
        <audio id='goblinShow' src={goblinShow}></audio>
        <audio id='mintSound' src={mintSound}></audio>
        <audio id='mintSound2' src={mintSound}></audio>
      </div>
    </section>
  )
}

export default MintSection;
