import React from "react";
import { Row, Col, Typography, Divider } from 'antd';
// import "./index.css";
// import background4 from "../static/bgs/bg4.png";

const { Title, Paragraph, Text, Link } = Typography;

class UtilitySection extends React.Component {
    render() {
        return (
            <div className="section section-utility">

                <div className="container">
                    <div className="con-left">
                        <div className="division div-1">
                            <Title className="feature-title">
                                <span>Utility</span>
                            </Title>
                            <Paragraph className="feature-box">
                                <span className="en-copy">Minting RavingGoblins will give you an access to the most prestigious degenerative party community in the world. We are not bringing party to you but we are here to build a party with you.<br/>We will create the biggest, coolest clubs to party the next millenia away in the metaverse. </span> 
                                <span className="ko-copy">레이빙 고블린을 통해 당신은 가장 권위 있는 디제네러티브 파티 커뮤니티의 멤버가 됩니다. 그리고 우리는 당신과 함께 파티를 즐기는 것을 넘어, 함께 멋진 파티를 만들어 갈 예정입니다. 우리 메타버스에서 앞으로 올 새로운 1천년 동안 가장 크고 멋있는 파티가 열릴 클럽을 함께 만들어요!</span>
                                <ul className="points">
                                    <li>
                                        <span className="en-copy">RavingGoblins EP Album Airdrop event  for  NFT holders</span>
                                        <span className="ko-copy">레이빙고블린 EP앨범을 NFT로 한정 발매하여 레이빙고블린 NFT소유자에게  에어드랍 이벤트 진행</span>
                                    </li>
                                    <li>
                                        <span className="en-copy">Future token air drop for all NFT holders</span>
                                        <span className="ko-copy">코인발행 시 레이빙고블린 NFT소유자에게 일정량 에어드랍</span>
                                    </li>
                                    <li>
                                        <span className="en-copy">Building success together as the community with an opportunity to invest in the latest songs of musicians and art works of upcoming digital artists.</span>
                                        <span className="ko-copy">협업 예정인 뮤직아티스트들과 발매하는 NFT음원 그리고 디지털아티스트들과 만드는 NFT아트 등의 작품에 선 투자를 할수 있는 기회를 제공</span>
                                    </li>
                                    <li>
                                        <span className="en-copy">First look and investment opportunity in songs (NFT) by RavingGoblins</span>
                                        <span className="ko-copy">레이빙고블린이 발행하는 NFT 음원에 선 투자 기회 제공</span>
                                    </li>
                                    <li>
                                        <span className="en-copy">NFT invites and VIP access for kick ass parties and festivals including meet and greet with artists </span>
                                        <span className="ko-copy">국내외 각종파티 및 페스티벌 초대권/할인권 제공 그리고 선별하여 VIP Access 제공(Meet&Greet등)</span>
                                    </li>
                                    <li>
                                        <span className="en-copy">Offline club benefits for all NFT holders</span>
                                        <span className="ko-copy">NFT 소유자에게 Offline club 할인 혜택 제공</span>
                                    </li>
                                    <li>
                                        <span className="en-copy">Invitation to top artist concerts</span>
                                        <span className="ko-copy">유명 국내외 아티스트가 공연 또는 참석하는 파티에 초청</span>
                                    </li>
                                </ul>
                            </Paragraph>
                        </div>
                    </div>
                    <div className="con-right">
                        <div className="division div-2">
                            <Title className="feature-title">
                                <span>Gacha</span>
                            </Title>
                            <Paragraph className="feature-box">
                                <span className="en-copy">Minting a RavingGoblin is a completely randomized with no control from the team and the supernatural power. </span>
                                <span className="ko-copy">가차(뽑기)를 통해 무작위하게 생성된 쿨하고 특별한 고블린들을 얻을 수 있습니다. </span>
                                <p className="points en-copy">The gacha system will give equal opportunity to be cool and fun a goblinly way.</p>
                                <p className="points ko-copy">레이빙고블린은 어떠한 외부의 통제 없이 무작위 시스템 아래에서 생성됩니다. 가차시스템을 통해 모두에게 멋지고 재미있게 레이빙고블린이 될 수 있는 동등한 기회를 부여합니다.</p>
                            </Paragraph>
                        </div>
                        {/* <div className="division div-3">
                            <Title className="feature-title">
                                <span>Rarity</span>
                            </Title>
                            <Paragraph className="feature-box">
                                <span className="en-copy">All Raving Goblins are cool and fun but some of them have traits that make them more cool and fun.</span>
                                <span className="ko-copy">모든 레이빙 고블린은 멋지고 특별하지만 그 중 일부는 더욱 특별하고 유니크한 특징을 가지고 있습니다. </span>
                                <ul className="points">
                                    <li>Common: 64.31%</li>
                                    <li>Uncommon: 19.91%</li>
                                    <li>Rare: 0.32%~0.86%</li>
                                    <li>SuperRare: 0.11%~0.29%</li>
                                    <li>Legend: 0.01%~0.10%</li>
                                </ul>
                            </Paragraph>
                        </div> */}
                    </div>
                </div>

            </div>
        )
    }
}

export default UtilitySection;