import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import React, { useEffect } from "react";
import { Layout, Menu } from 'antd';
import ReactDOM, { findDOMNode } from "react-dom";
import 'react-app-protect/dist/index.css';
import ReactFullpage from "@fullpage/react-fullpage";
import "antd/dist/antd.css";
import "./index.css";
import ReactGA from 'react-ga';
import './resource/css/index.css'
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

// Importing the Header, Content, and Footer components from the Layout component
const { Header, Content } = Layout;

const subgraphUri = "http://localhost:8000/subgraphs/name/scaffold-eth/your-contract";
 
const client = new ApolloClient({
  uri: subgraphUri,
  cache: new InMemoryCache(),
});

// Initialize the Google Analytics tracker
const GAComponent = () => {
  ReactGA.initialize('UA-215916583-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
 // ReactGA.pageview('/'); // returns only the homepage view
}

(()=> {
  let $window = $(window)
  const bgmBtn = $(".rhap_play-pause-button")

  $(function(){
    const lang = navigator.language

    if(lang == 'ko-KR'){
      $('html').removeClass('en').addClass('ko')
    }else{
      $('html').removeClass('ko').addClass('en')
    }

    $window.on('load',function(){
      if(bgmBtn.hasClass('rhap_play-pause-button')){
        setTimeout(function(){
          bgmBtn.trigger("click")
        },1000)
      }
    })
  });

 
  if($window.outerWidth() < 1024){
    $('html').addClass('device')
    $('html').on('click', function (e) {
      if(!$(e.target).closest('.ant-layout-header').length && $('.device-menu').hasClass('active') && $('html').hasClass('device')){
        $('#gnb-list').slideUp()
        $('.device-menu').toggleClass('active')
      }
    })
  }else{
    $('html').removeClass('device')
  }

  $window.resize(function(){
    if($window.outerWidth() < 1024){
      $('html').addClass('device')
      $('#gnb-list').hide()
    }else{
      $('html').removeClass('device')
      $('#gnb-list').show()
    }
    $('.device-menu').removeClass('active')
  });

    // $(document).on('scroll',function() {
    //   if(pageYOffset > 0  && bgmBtn.hasClass('rhap_play-pause-button')){
    //     bgmBtn.trigger("click")
    //   }
    // })
})()



/** Importing Background Components for this SPA */
import { HomeSection, AboutSection, EventSection, MintSection, UtilitySection, RoadmapSection, TeamSection, PartnerSection } from "./components";
import { AuthProvider } from "./context/Context";
import Socials from "./components/Socials";
import MusicPlayer from "./components/MusicPlayer";
const anchors = [ "Home", "About", "Mint", "Utility", "Roadmap", "Team", "Partner"];

const FullpageWrapper = () => (
  useEffect(() => {
    GAComponent();
  },[]),

  <ReactFullpage
    licenseKey={'2C3F4625-E1BA4DB2-B8634B88-4ED05985'}
    anchors={anchors}
    // navigation 
    // navigationTooltips={anchors}
    fitToSection={false}
    lockAnchors= {false}
    autoScrolling={false}
    onLeave={(origin, destination, direction) => {
      console.log("onLeave", origin, destination, direction);
    }}
    render={({ state, fullpageApi }) => {
      console.log("render prop change", state, fullpageApi);
      if($('.device-menu').hasClass('active')){
        $('#gnb-list').slideUp()
        $('.device-menu').toggleClass('active')
      }
      return ( 
        <div>
          
          <HomeSection />
          <AboutSection />
          <MintSection />
          <UtilitySection />
          <RoadmapSection />
          {/* <RaritySection /> */}
          <TeamSection />
          <PartnerSection />
        </div>
        
      );
    }}
  />
)

ReactDOM.render(
  <ApolloProvider client={client}>
    <AuthProvider>
    <Layout className="layout">
    
      {/* <Socials /> */}
    
      <Header 
          style={{ position: 'fixed', zIndex: 1, width: '100%', backgroundColor: '#23003F' }}
      ><div className="header-wrap">

          <div className="header-logo">        
            <a href="#Home"></a>
          </div>
          <Menu id="gnb-list">
              {/* <Menu.Item key={"Home"}><a href="#Home">HOME</a></Menu.Item> */}
              <Menu.Item key={"About"}><a href="#About">ABOUT</a></Menu.Item>
              <Menu.Item key={"Mint"}><a href="#Mint">MINT</a></Menu.Item>
              <Menu.Item key={"Utility"}><a href="#Utility">UTILITY</a></Menu.Item>
              <Menu.Item key={"Roadmap"}><a href="#Roadmap">ROADMAP</a></Menu.Item>
              {/* <Menu.Item key={"Rarity"}><a href="#Rarity">RARITY</a></Menu.Item> */}
              <Menu.Item key={"Team"}><a href="#Team">TEAM</a></Menu.Item>
              <Menu.Item key={"Partner"} className="cumunity">
                <a href="#Partner" className="cumunity-menu">
                COMMUNITY 
                <span> 
                  <ul className="sns-list">
                    <li>
                      <a className="discord" target="_blank" href="https://discord.gg/54rYGQMGKN">Discord</a>
                    </li>
                    <li>
                      <a className="twitter" target="_blank" href="https://twitter.com/raving_goblins">Twitter</a>
                    </li>
                    <li>
                      <a className="opensea" href="https://opensea.io/collection/ravinggoblinsclub" target="_blank">Opensea</a>
                    </li>
                    <li>
                      <a className="instagram" target="_blank"href="https://www.instagram.com/raving_goblins/">Instagram</a>
                    </li>
                    <li>
                      <a className="telegram" target="_blank" href="https://t.me/raving_goblins   ">Telegram</a>
                    </li>
                    <li>
                      <a className="kakao" target="_blank" href="https://open.kakao.com/o/gvZM6UYd">Kakao</a>
                    </li>
                  </ul>
                </span>
                </a>
              </Menu.Item>
              <Menu.Item id="device-trans-btn">
                <button className="translate-btn" onClick={
                    function transLang() {
                      if($('html').hasClass('en')){
                        $('html').removeClass('en').addClass('ko')
                      }else{
                        $('html').removeClass('ko').addClass('en')
                      }
                    }
                  }>
                  <span className="trans-text ko-copy">ENG</span>
                  <span className="trans-text en-copy">KOR</span>
                </button>
              </Menu.Item>
          </Menu>
          <div className="header-button-wrap">
            <button className="translate-btn" onClick={
                function transLang() {
                  if($('html').hasClass('en')){
                    $('html').removeClass('en').addClass('ko')
                  }else{
                    $('html').removeClass('ko').addClass('en')
                  }
                }
              }>
              <span className="trans-text ko-copy">ENG</span>
              <span className="trans-text en-copy">KOR</span>
            </button>
            <button className="device-menu" onClick={
                function mobileMenu() {
                  $('.device-menu').toggleClass('active')
                  if($('.device-menu').hasClass('active')){
                    $('#gnb-list').slideDown()
                  }else{
                    $('#gnb-list').slideUp()
                  }
                }
              }>
              <span className="menu-icon"></span>
            </button>
          </div>
      </div>
      </Header>
    </Layout>
    <Content>
      <FullpageWrapper />
      <MusicPlayer />
    </Content>
    {/* <Footer 
      style={{ 
        textAlign: 'center', 
        color: 'white', 
        backgroundColor: '#300449',
        padding: '2em',
      }}>
        Every Single Moment ©2021 Crafted by David
      </Footer> */}
  </AuthProvider>
  </ApolloProvider>,
  document.getElementById("root"),
);