import React from "react";

class HomeSection extends React.Component {
    render() {
        (function () {
            // console.log('@@@');
            // $('.pushable').css('width',500)
        })()
        return (
            <section className="section home-section">
                <div className="logo-wrap">
                    <h1 className="title-logo">Raving Goblins</h1>
                    <span className="down-arrow"></span>
                </div>
                <div className="bg-wrap">
                    <div className="sky">
                        <p className="cloud-b"></p>
                        <p className="cloud-m"></p>
                        <p className="cloud-s"></p>
                        <div className="stars">
                            <p className="mirrorball"></p>
                            <p className="star-s"></p>
                            <p className="meteor"></p>
                        </div>
                    </div>
                    
                    <div className="goblins">
                        <p className="goblin1"></p>
                        <p className="goblin2"></p>
                        <p className="goblin3">
                            <span className="center-goblin"></span>
                        </p>
                        <p className="goblin4"></p>
                        <p className="goblin5"></p>
                    </div>              
                </div>
            </section>
        )
    }
}

export default HomeSection;