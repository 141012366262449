import React from "react";
import { Row, Col, Typography, Divider } from 'antd';
// import "./index.css";
// import background5 from "../resource/images/rarity/pc_bg.jpg";

const { Title, Paragraph, Text, Link } = Typography;

class RoadmapSection extends React.Component {
    render() {
        return (
            <div className="section section-roadmap">
                
                <div className="container">
                    <div className="con-title">
                        <Title className="feature-title">
                            <span>Roadmap</span>
                        </Title>
                    </div>
                    <div className="cloud-wrap">
                        <span className="cloud1"></span>
                        <span className="cloud2"></span>
                        <span className="cloud3"></span>
                        <span className="cloud4"></span>
                        <span className="cloud5"></span>
                    </div>
                    <div className="con-step">
                        <div className="row">
                            <div className="step feature-box step-1">
                                <span className="num"></span>
                                <span className="en-copy">
                                    <strong className="p-tit">Phase 1. The First Drop: The first drop of Ravling Goblins in KR.</strong>
                                    <span className="p-desc"> And we will have second drop in TH and VN soon</span>
                                </span>
                                <span className="ko-copy">
                                    <strong className="p-tit"> Phase 1. 첫번째 드랍을 통해 레이빙 고블린이 한국에서 발행됩니다.</strong>
                                    <span className="p-desc"> 첫 드랍이 성공적으로 종료되면 두번째 드랍을 태국과 베트남에서 시작 할 예정입니다.</span>
                                </span>
                                <span className="next right"></span>
                            </div>
                            <div className="step feature-box step-2">
                                <span className="num"></span>
                                    <span className="en-copy">
                                        <strong className="p-tit">Phase 2. The RavingGoblins EP Album airdrop event for those who minted RavingGoblins</strong>
                                    </span>
                                    <span className="ko-copy">
                                        <strong className="p-tit">Phase 2. 레이빙고블린 EP</strong>
                                        <span className="p-desc"> 앨범을 NFT로 발매하여 레이빙고블린 NFT소유자에게 에어드랍 이벤트를 진행할 계획입니다. </span>
                                    </span>
                                <span className="next"></span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="step feature-box step-3">
                                <span className="num"></span>
                                    <span className="en-copy">
                                       <strong className="p-tit">Phase 3. RavingGoblins Squid Game: </strong>  
                                       <span className="p-desc"> Among 10,000 RavingGoblins will have an opportunity to participate in a virtual Squid Game to win a grand prize. </span>
                                    </span>
                                    <span className="ko-copy">
                                       <strong className="p-tit">Phase 3. 오징어 게임 :</strong> 
                                       <span className="p-desc"> 레이빙 고블린 홀더는 온라인에서 진행되는 오징어 게임에 참가 할 수 있고 상금과 파티를 모두 가질 수 있습니다.</span>
                                    </span>
                                <span className="next left"></span>
                            </div>
                            <div className="step feature-box step-4">
                                <span className="num"></span>
                                    <span className="en-copy">
                                        <strong className="p-tit">Phase 4.</strong>
                                        <span className="p-desc"> Goblin Juice is randomly dropped to holders to upgrade Goblins to V2 </span>
                                    </span>
                                    <span className="ko-copy">
                                        <strong className="p-tit">Phase 4.</strong>
                                        <span className="p-desc"> 고블린주스가 홀더들에게 랜덤하게 제공되어 레이빙고블린 Version 2로의 업그레이드가 가능합니다.</span>
                                    </span>
                                <span className="next"></span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="step feature-box step-5">
                                <span className="num"></span>
                                    <span className="en-copy">
                                        <strong className="p-tit">Phase 5. Block Party: </strong>
                                        <span className="p-desc">  Aug in 2022, Goblin holders will be invited to Block Party to be held in DDP, Seoul, South Korea. </span>
                                    </span>
                                    <span className="next right"></span>
                                    <span className="ko-copy">
                                        <strong className="p-tit">Phase 5. 블록파티: </strong>
                                        <span className="p-desc"> 2022년 8월, 고블린 홀더들은 서울 동대문 DDP에서 열리는 페스티벌에 초대됩니다.</span>
                                    </span>
                            </div>
                        <div className="step feature-box step-6">
                                <span className="num"></span>
                                <span className="en-copy">
                                    <strong className="p-tit">Phase 6. Special Edition Goblins: </strong>
                                    <span className="p-desc"> Special Goblins made by and with celebrities</span>
                                </span>
                                <span className="ko-copy">
                                    <strong className="p-tit">Phase 6.</strong>
                                    <span className="p-desc"> 다수의 힙합아티스트등 협의중인 셀럽들의 <strong className="p-tit">스페셜 에디션 발행</strong> </span>
                                </span>
                                <span className="next"></span>
                        </div>
                        </div>
                        <div className="row">
                            <div className="step feature-box step-7">
                                <span className="num"></span>
                                <span className="en-copy">
                                    <strong className="p-tit">Phase 7. RavingGoblins Token release: </strong>
                                    <span className="p-desc"> RavingGoblins Token will be used for music and Festival industries and airdrop to all RavingGoblins NFT holders</span>
                                </span>
                                <span className="ko-copy">
                                    <strong className="p-tit">Phase 7. </strong>
                                    <span className="p-desc"> 레이빙고블린 코인이 발행되어 뮤직 및 페스티벌 인더스트리에 사용될 예정이며, 모든 레이빙고블린 NFT소유자에게 에어드랍될 계획입니다. </span>
                                </span>
                                <span className="next left"></span>
                            </div>
                            <div className="step feature-box step-8">
                                <span className="num"></span>
                                <span className="en-copy">
                                    <strong className="p-tit">Phase 8. Implementing RavingGoblins DAO: </strong> 
                                    <span className="p-desc"> DAO will help create a community focused on deciding the direction of the goblins, the expansion of its ecosystem, and how to curate and strengthen the best of technical talent to manifest creativity in Web3</span>
                                </span>
                                <span className="ko-copy">
                                    <strong className="p-tit">Phase 8. 레이빙고블린 DAO :</strong> 
                                    <span className="p-desc"> 레이빙 고블린은 DAO를 통해 커뮤니티가 함께 레이빙 고블린의 방향성과 생태계의 확장을 결정할 예정입니다. 아울러, Web3를 통한 새로운 기술적 그리고 예술적 창의성이 발현되기 위해 필요한 최고의 엔지니어, 파티 및 페스티벌 아티스트들의  선별을 DAO를 통해 진행하고 결정할 예정입니다.</span>
                                </span>
                                <span className="next"></span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="step feature-box step-9">
                                <span className="num"></span>
                                <span className="en-copy">
                                    <strong className="p-tit">Phase 9. RavingGoblins Merch Drop</strong> 
                                </span>
                                <span className="ko-copy">
                                    <strong className="p-tit">Phase 9. 레이빙 고블린 굿즈 제작</strong> 
                                </span>
                                <span className="next right"></span>
                            </div>
                            <div className="step feature-box step-10">
                                <span className="num"></span>
                                <span className="en-copy">
                                    <strong className="p-tit">Phase 10. Asian conquest: </strong> 
                                    <span className="p-desc"> Block Party will be held in Southeast Asia.</span>
                                </span>
                                <span className="ko-copy">
                                    <strong className="p-tit">Phase 10. 동남아시아지역에서 브레이킹던 페스티벌 개최</strong> 
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RoadmapSection;