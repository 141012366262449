import React from "react";
import { Row, Col, Typography, Divider } from 'antd';
import "./index.css";
import background2 from "../resource/images/about/about_bg_pc.jpg";

const { Title, Paragraph, Text, Link } = Typography;

class AboutSection extends React.Component {
    render() {
        return (
            <div className="section section-about"
                style={{ 
                    backgroundImage: `url(${background2})`,
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}>

                <div className="container">
                    <div className="con-showcase">
                        <div className="showcase"></div>
                        <div className="goblin"></div>
                    </div>
                    <div className="con-box text-up">
                        <button className="up-arrow" onClick={
                            function textUp() {
                                $('.con-box ').removeClass('text-down').addClass('text-up')
                            }
                          }></button>
                        <div className="box feature-box">
                            <div className="feature-box-wrap">
                                <div className="feature-overflow">
                                    <p className="title">
                                        <span className="en-copy"> 10,000+ unique goblins from Musica planet at the end of our space!</span>
                                        <span className="ko-copy">외은하의 끝 뮤지카 행성에서 온 10,000+명의 특별하고 멋진 고블린!</span>
                                    </p>
                                    <div className="content en-copy">
                                        <p>RavingGoblins come from Musica, a small planet hidden few light years away. Their seemingly chaotic world runs on a cosmic energy fueled by music, dance and art. In order to create the energy they are bound to party 24/7, making them the biggest party lovers in the known and unknown galaxies. </p>
                                        <p>RavingGoblins are the coolest and sickest intergalactic dancers always looking to rave. Now, they are bringing their rave to Earth. Are you ready to party?</p>
                                        <strong>Only 10,456 unique RavingGoblins will participate in the first rave.</strong>
                                    </div>
                                    <div className="content ko-copy">
                                        <p>레이빙고블린은 지구에서 몇 광년 떨어진 먼 우주에 숨겨진 작은 행성 ‘Musica’에서 왔습니다. 이 무질서한 세상에서 레이빙고블린들은 음악, 춤 그리고 예술을  에너지원으로 살아갑니다. 생존을 위해 레이빙고블린들은 쉬지않고 파티를 하고, 그런 파티를 통해 그들은 자연스럽게 은하계를 포함한 모든 우주에서 가장 잘 노는 생명체가 됩니다. 레이빙고블린은 가장 쿨하고 멋진 우주 최고의 댄서들로 항상 광란의 파티를 찾습니다. </p> 
                                        <p>이제,그들이 자신들의 특별한 파티를 우리와 함께 하기 위해 지구로 오려 합니다. </p>
                                        <strong>여러분, 10,456명의 유니크한 레이빙고블린들이 참여하는 이번 첫번째 파티를 함께 즐길 준비 되셨나요?</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="down-arrow" onClick={
                            function textDown() {
                                $('.con-box ').removeClass('text-up').addClass('text-down')
                            }
                          }></button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutSection;